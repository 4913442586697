<template>
  <div
    class="flex max-w-max items-center border border-divider cursor-default rounded px-4 py-2 space-x-2 whitespace-nowrap h-10"
    data-cy="checkmark-tab"
    :class="
      active
        ? 'font-semibold bg-surface-user-blue text-primary border-transparent'
        : 'text-label'
    "
  >
    <div class="flex items-center">
      <span v-if="$slots.prefix">
        <span class="font-normal">
          <slot name="prefix" />
        </span>
      </span>
      <slot />
      <span v-if="$slots.suffix">
        <span class="mx-1">•</span>
        <span class="font-normal">
          <slot name="suffix" />
        </span>
      </span>
      <icon-base v-if="showLock" class="mx-1" icon="lock" />
    </div>
    <div
      v-if="showCheckmark"
      class="flex items-center"
      :class="{
        'text-primary': active,
        'text-gray-400': !active
      }"
    >
      <div
        v-if="checkmarkDescription"
        v-tooltip="checkmarkDescription"
        class="font-normal"
      >
        <icon-base
          height="16"
          width="16"
          icon="success-round-big"
          @click.stop.prevent="handleCheckmarkClick"
        />
      </div>
      <icon-base v-else height="16" width="16" icon="success-round-big" />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (eventName: "checkmarkClicked"): void;
}>();

defineProps({
  active: {
    type: Boolean,
    default: false
  },
  showCheckmark: {
    type: Boolean,
    default: false
  },
  showLock: {
    type: Boolean,
    default: false
  },
  checkmarkDescription: {
    type: String,
    default: ""
  }
});

const handleCheckmarkClick = () => {
  emit("checkmarkClicked");
};
</script>
